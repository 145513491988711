.Card {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.Card:hover {
  transform: translateY(-4px);
}

.team-col {
	border: 1px solid #444;
	border-radius:5px;
}

.left-text {
	text-align: left;
}

.crumby {
  border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0rem !important;
    border-top-left-radius: 0rem !important; 
}

.backarea, .teamcolor {
  cursor: pointer;
}

.teamName {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.colorSwatch {
  width: 1.35rem;
  height: 1.35rem;
  transition: transform 0.3s;
}

.colorSwatch:hover {
  transform: scale(1.2);
}


.SinglecolorSwatch {
  width: 3.35rem;
  height: 3.35rem;
  border-radius:10px;
  transition: transform 0.3s;
}

.SinglecolorSwatch:hover,{
  transform: scale(1.2);
}


.left-text {
  white-space: nowrap;
}

.container-right {
  padding-right: 20px;
}

.league-img {

  height: 100px;
  width: auto;
}
.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
}

.text-logo {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.icon-container {
  display: flex;
  align-items: center;
}

.logo-icon {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  cursor: pointer;
}

.text-light-blue-500 {
  color:#000 !important;;
}

.teamcolor {
  border: 1px solid #888;
  transition: transform 0.3s;
}

.teamcard {
    transition: transform 0.3s;
    cursor: default;
}

.teamcard p {
  cursor: pointer !important;
}

.teamcard:hover {
     transform: scale(1.05);

}

.teamcolor:hover {
   transform: scale(1.05);
}

.teamcolor:clicked {
   transform: scale(1.03);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #484848;
  font-size: 22px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 200px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

.dropdown-menu .dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f1f5f9;
}


.leaguelinks {
    transition: transform 0.3s;
}

.leaguelinks:hover {
     transform: scale(1.05);

}

.dropdown-menu-mobile {
  display: none;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 100;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
}


.sitelogo {
  flex: 1;
  text-align: center;
}

.nav-list {
  flex: 1;
  justify-content: center;
}

@media (max-width:768px) {
  .dropdown:hover .dropdown-menu {
    display: inline;
    position: fixed;
    left: 0;
    width: 100%;
    margin-top: 8px;
    border-radius: 0px;
}


nav {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}


.dropdown-button {
    font-size: 20px;
    margin: 8px;
}

}

@media (min-width: 640px) {
  .sitelogo {
    flex: none;
    text-align: left;
  }



  .nav-list {
    flex: none;
    justify-content: flex-start;
  }
}
